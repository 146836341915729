<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">How to find the ideal online tutor/teacher?</h2>
    </section>
    <div class="container 75%">
      <section class="myspanishnow-story row 200%">
        <div>
          <p>Hola {{ getCurrentSeasonHeart() }}</p>
          <p>In my opinion depending on the person <i>the ideal</i> as a concept changes. There is no such definition of the ideal teacher  that is valid for everyone. So, firstly, take a few seconds and think WHAT means <i>the ideal teacher</i> for you?</p>
          <p>My advice is don't get too crazy looking for the perfect Spanish teacher, why? Because perfection does not exist. But, please, take your time thinking well about what you need - or what you don't - this way it will be much easy for you to find the best teacher.</p>
          <blockquote class="blockquote text-center">
            <p>When I was learning English my perfect tutor was with whom I got along with. Getting along with my student is priceless. That’s my ideal concept.</p>
          </blockquote>
          <p>And why is that connection between teacher and student so important? Because learning a language can be embarrassing and sometimes you can even feel ridiculous or insecure. If this is your case, maybe one to one lessons are a best fit. This will help you to feel more comfortable during your lessons.</p>
          <p>Also, most of the teachers do offer a free trial lesson, so go for it and see if you feel confident - forget about your fears - and most important: enjoy learning Spanish! Avoid disappointments!</p>
          <p>Do you know what type of Spanish teacher are you looking for now? If still unsure, write us via <a href="/contact?subject=Question%20-%20How%20to%20find%20the%20ideal%20online%20tutor%2Fteacher" target="_blank">our website</a>.</p>
          <p>See you soon until then, DISFRUTA 🤓 {{ getCurrentSeasonEmoji() }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'FindThePerfectTutor',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }
</style>
